import * as React from "react";
import Layout from "../components/layout";
import HeroSection from "../components/HeroSection/HeroSection";
import AboutSection from "../components/AboutSection/AboutSection";
import FeaturesInteractiveSection from '../components/plantaoFacil/FeaturesInteractiveSection';
import BenefitsSection from "../components/BenefitsSection/BenefitsSection";
import TestimonialsSection from "../components/TestimonialsSection/TestimonialsSection";
import PricingSection from "../components/plantaoFacil/PricingSection";
import FaqSection from "../components/FaqSection/FaqSection";
import CallToActionSection from "../components/CallToActionSection/CallToActionSection";
import Seo from "../components/Seo";
import LatestNewsSection from "../components/LatestNewsSection/LatestNewsSection";
import "./index.css";

const IndexPage = () => {
  return (
    <>
      <Seo
        title="Prontuário Fácil - Gerenciamento eficiente para clínicas e consultórios"
        description="Gerencie prontuários médicos online com facilidade. Software SaaS de saúde para clínicas e consultórios. Simplifique o atendimento e a organização."
        keywords="prontuário eletrônico, saúde, clínicas, médicos, enfermeiros"
      />
      <Layout>
        <HeroSection />
        <AboutSection />
        <FeaturesInteractiveSection />
        <BenefitsSection />
        <TestimonialsSection />
        <PricingSection />
        <FaqSection />
        <CallToActionSection />
        <LatestNewsSection />
      </Layout>
    </>
  );
};

export default IndexPage;