import React from 'react';
import './BenefitsSection.css';

const BenefitsSection = () => {
  return (
    <section className="home-benefits-section" aria-labelledby="benefits-section-title">
      <div className="home-benefits-container">
        <h2 id="benefits-section-title">Por que escolher o Prontuário Fácil?</h2>
        <div className="home-benefits-grid">
          <div className="home-benefit-item">
            <h3>Prontuário Eletrônico Completo</h3>
            <p>Integre todas as informações do paciente em um só lugar, acessível de qualquer dispositivo.</p>
          </div>
          <div className="home-benefit-item">
            <h3>Gestão de Agendamentos</h3>
            <p>Facilite o agendamento de consultas com um sistema intuitivo e flexível.</p>
          </div>
          <div className="home-benefit-item">
            <h3>Relatórios Inteligentes</h3>
            <p>Tome decisões baseadas em dados com relatórios detalhados e análises avançadas.</p>
          </div>
          <div className="home-benefit-item">
            <h3>Segurança dos Dados</h3>
            <p>Garanta a segurança das informações com protocolos avançados de proteção.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BenefitsSection;
