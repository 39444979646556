import React, { useState } from 'react';
import { FiPlus, FiMinus } from 'react-icons/fi';
import './FaqSection.css';

const FaqSection = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = index => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <section className="faq-section" aria-labelledby="faq-section-title">
            <div className="container-faq">
                <h2 id="faq-section-title">Perguntas Frequentes</h2>
                <div className="faq-container">
                    {[
                        {
                            question: "Como o Prontuário Fácil pode melhorar o meu dia-a-dia?",
                            answer: "O Prontuário Fácil ajuda a automatizar processos, tornando a gestão da clínica mais eficiente e dando mais tempo para você se concentrar no atendimento ao paciente."
                        }, 
                        {
                            question: "É fácil integrar o Prontuário Fácil com outros sistemas?",
                            answer: "Sim, o Prontuário Fácil foi projetado para ser integrado facilmente com uma variedade de sistemas e plataformas de terceiros para garantir uma experiência de usuário fluida e conectada."
                        }, 
                        {
                            question: "O Prontuário Fácil está em conformidade com as normas de privacidade e segurança de dados?",
                            answer: "Sim, levamos a segurança e a privacidade dos dados muito a sério e cumprimos integralmente as normas de proteção de dados vigentes."
                        }, 
                        {
                            question: "Qual o nível de suporte oferecido aos usuários do Prontuário Fácil?",
                            answer: "Oferecemos um suporte abrangente, incluindo um centro de ajuda online, suporte técnico por chat e telefone, e uma base de conhecimento detalhada."
                        }, 
                        {
                            question: "Como posso personalizar o Prontuário Fácil para atender às necessidades específicas da minha clínica?",
                            answer: "O Prontuário Fácil oferece várias opções de personalização, permitindo que você configure funcionalidades, fluxos de trabalho e relatórios para atender às demandas específicas da sua operação."
                        }
                    ].map((item, index) => (
                        <div
                            key={index}
                            className={`faq-item ${activeIndex === index ? 'open' : ''}`}
                            onClick={() => toggleFAQ(index)}
                            onKeyPress={(e) => (e.key === 'Enter' || e.key === ' ') && toggleFAQ(index)}
                            tabIndex={0}
                            role="button"
                            aria-expanded={activeIndex === index}
                            aria-controls={`faq-answer-${index}`}
                        >
                            <div className="faq-question">
                                <h3 id={`faq-question-${index}`}>{item.question}</h3>
                                <span>{activeIndex === index ? <FiMinus /> : <FiPlus />}</span>
                            </div>
                            {activeIndex === index && (
                                <div className="faq-answer" id={`faq-answer-${index}`} role="region" aria-labelledby={`faq-question-${index}`}>
                                    <p>{item.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FaqSection;
