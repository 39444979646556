import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import './HeroSection.css';
import { Link } from 'gatsby';

const HeroSection = () => {
  return (
    <section className="hero-section" aria-labelledby="hero-title" aria-describedby="hero-description">
      <div className="hero-image">
        <StaticImage
          src="../../assets/1.webp"
          alt="Background do Prontuário Fácil"
          placeholder="blurred"
          layout="fullWidth"
          quality={95}
          formats={['auto', 'webp', 'avif']}
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1 }}
        />
        <div className="overlay" aria-hidden="true"></div>
      </div>
      <div className="hero-content">
        <h2 id="hero-title">Gerenciamento eficiente para clínicas e consultórios</h2>
        <p id="hero-description">Maximize a produtividade e melhore a experiência do paciente com o Prontuário Fácil.</p>
        <Link to="/novo-usuario" id="botao-teste-gratis" className="cta-button" aria-label="Iniciar Teste Grátis">
          Teste Grátis
        </Link>
      </div>
    </section>
  );
};

export default HeroSection;
