import React from 'react';
import './TestimonialsSection.css';

const testimonials = [
  {
    quote: "O Prontuário Fácil transformou o fluxo de trabalho da nossa clínica. Estamos atendendo mais pacientes com muito mais eficiência.",
    name: "Dr. Ana Beatriz",
  },
  {
    quote: "A facilidade de acesso aos prontuários e a agilidade no agendamento melhoraram nosso atendimento.",
    name: "Dr. Carlos Eduardo",
  },
  // Adicione mais depoimentos aqui
];

const TestimonialsSection = () => {
  return (
    <section className="testimonials-section">
      <div className="container">
        <h2>O que nossos clientes dizem</h2>
        <div className="testimonials-grid">
          {testimonials.map((testimonial, index) => (
            <div className="testimonial-item" key={index}>
              <blockquote>
                "{testimonial.quote}"
              </blockquote>
              <p className="customer-name">- {testimonial.name}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
