import React from 'react';
import './AboutSection.css';
import logo from '../../assets/logo-green.svg';

const AboutSection = () => {
  return (
    <section className="about-section" aria-labelledby="about-section-title" aria-describedby="about-section-description">
      <div className="about-content">
        <h2 id="about-section-title">Sobre o Prontuário Fácil</h2>
        <div id="about-section-description">
          <p>O Prontuário Fácil é uma solução completa de gestão para clínicas e consultórios que integra prontuário eletrônico, agendamentos, finanças e muito mais em uma única plataforma acessível e fácil de usar.</p>
          <p>Nossa missão é simplificar a rotina dos profissionais de saúde, oferecendo as ferramentas que eles precisam para fornecer o melhor atendimento aos pacientes.</p>
        </div>
      </div>
      <div className="about-content-logo">
        <img src={logo} alt="Logo do Prontuário Fácil" className="prontuario-facil-logo" />
      </div>
    </section>
  );
};

export default AboutSection;
