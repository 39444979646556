import React, { useState } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import './LatestNewsSection.css';

const LatestNewsSection = () => {
    const data = useStaticQuery(graphql`
        query {
            allSanityPost(sort: { fields: publishedAt, order: DESC }) {
                edges {
                    node {
                        id
                        title
                        slug {
                            current
                        }
                        excerpt
                        mainImage {
                            asset {
                                url
                            }
                        }
                        publishedAt(formatString: "MMMM DD, YYYY")
                    }
                }
            }
        }
    `);

    const posts = data?.allSanityPost?.edges || [];
    const postsPerPage = 3;
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <section className="latest-news-section" aria-labelledby="latest-news-title">
            <div className="news-content">
                <h2 id="latest-news-title" className="news-title">Postagens Recentes</h2>
                <div className="news-grid">
                    {currentPosts.map(({ node }) => (
                        <Link 
                            to={`/blog/${node.slug.current}`} 
                            key={node.id} 
                            className="news-item"
                            aria-label={`Leia mais sobre ${node.title}`}
                        >
                            {node.mainImage?.asset?.url && (
                                <img
                                    src={node.mainImage.asset.url}
                                    alt={`Imagem representando ${node.title}`}
                                    className="news-thumbnail"
                                    loading="lazy"
                                />
                            )}
                            <div className="news-info">
                                <h3 className="news-item-title">{node.title}</h3>
                                {node.excerpt && <p className="news-item-summary">{node.excerpt}</p>}
                                <p className="news-item-date">{node.publishedAt}</p>
                            </div>
                        </Link>
                    ))}
                </div>
                <div className="pagination" aria-label="Paginação das postagens recentes">
                    {Array.from({ length: Math.ceil(posts.length / postsPerPage) }, (_, i) => (
                        <button
                            key={i + 1}
                            onClick={() => paginate(i + 1)}
                            className={`pagination-btn ${currentPage === i + 1 ? 'active' : ''}`}
                            aria-label={`Página ${i + 1} ${currentPage === i + 1 ? '(atual)' : ''}`}
                            aria-current={currentPage === i + 1 ? 'page' : undefined}
                        >
                            {i + 1}
                        </button>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default LatestNewsSection;
